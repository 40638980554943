import kuda from '../../assets/ecosystem-logos/kuda.svg';
import subsea from '../../assets/ecosystem-logos/subsea.svg';
import slashproof from '../../assets/ecosystem-logos/slashproof.svg';
import wormhole from '../../assets/ecosystem-logos/wormhole.svg';
import spacetime from '../../assets/ecosystem-logos/spacetime.svg';
import hyperbolic from '../../assets/ecosystem-logos/hyperbolic.svg';
import hypernative from '../../assets/ecosystem-logos/hypernative.svg';
import hyperlane from '../../assets/ecosystem-logos/hyperlane.svg';
import pendle from '../../assets/ecosystem-logos/pendle.svg';
import coinbase from '../../assets/ecosystem-logos/coinbase.svg';
import binance from '../../assets/ecosystem-logos/binance.svg';
import ethereum from '../../assets/ecosystem-logos/ethereum.svg';
import arbitrum from '../../assets/ecosystem-logos/arbitrum.svg';
import circle from '../../assets/ecosystem-logos/circle.svg';
import bnb from '../../assets/ecosystem-logos/bnb.svg';
import tether from '../../assets/ecosystem-logos/tether.svg';
import mantle from '../../assets/ecosystem-logos/mantle.svg';
import k2 from '../../assets/ecosystem-logos/k2.svg';
import interstate from '../../assets/ecosystem-logos/interstate.svg';
import lido from '../../assets/ecosystem-logos/lido.svg';
import caldera from '../../assets/ecosystem-logos/caldera.svg';
import etherfi from '../../assets/ecosystem-logos/etherfi.svg';
import spark from '../../assets/ecosystem-logos/spark.svg';
import bitgo from '../../assets/ecosystem-logos/bitgo.svg';
import ethena from '../../assets/ecosystem-logos/ethena.svg';
import rocketPool from '../../assets/ecosystem-logos/rocket-pool.svg';
import originEther from '../../assets/ecosystem-logos/origin-ether.svg';
import frax from '../../assets/ecosystem-logos/frax.svg';
import renzo from '../../assets/ecosystem-logos/renzo.svg';
import redacted from '../../assets/ecosystem-logos/redacted.svg';
import swell from '../../assets/ecosystem-logos/swell.svg';
import kelp from '../../assets/ecosystem-logos/kelp.svg';
import puffer from '../../assets/ecosystem-logos/puffer.svg';
import magpie from '../../assets/ecosystem-logos/magpie.svg';
import bedrock from '../../assets/ecosystem-logos/bedrock.svg';
import stader from '../../assets/ecosystem-logos/stader.svg';
import lista from '../../assets/ecosystem-logos/lista.svg';
import solv from '../../assets/ecosystem-logos/solv.svg';
import nethermind from '../../assets/ecosystem-logos/nethermind.svg';
import animoca from '../../assets/ecosystem-logos/animoca.svg';
import hashkey from '../../assets/ecosystem-logos/hashkey.svg';
import okx from '../../assets/ecosystem-logos/okx.svg';
import ankr from '../../assets/ecosystem-logos/ankr.svg';
import dsrv from '../../assets/ecosystem-logos/dsrv.svg';
import proofgroup from '../../assets/ecosystem-logos/proof-group.svg';
import infstones from '../../assets/ecosystem-logos/infstones.svg';
import validationcloud from '../../assets/ecosystem-logos/validation-cloud.svg';
import infrasingularity from '../../assets/ecosystem-logos/infrasingularity.svg';
import rockx from '../../assets/ecosystem-logos/rockx.svg';
import luganodes from '../../assets/ecosystem-logos/luganodes.svg';
import piertwo from '../../assets/ecosystem-logos/piertwo.svg';
import finoa from '../../assets/ecosystem-logos/finoa.svg';
import validao from '../../assets/ecosystem-logos/validao.svg';
import kiln from '../../assets/ecosystem-logos/kiln.svg';
import a41 from '../../assets/ecosystem-logos/a41.svg';
import meria from '../../assets/ecosystem-logos/meria.svg';
import allnodes from '../../assets/ecosystem-logos/allnodes.svg';
import everstake from '../../assets/ecosystem-logos/everstake.svg';
import ignition from '../../assets/ecosystem-logos/ignition.svg';
import elixir from '../../assets/ecosystem-logos/elixir.svg';
import usual from '../../assets/ecosystem-logos/usual.svg';
import derive from '../../assets/ecosystem-logos/derive.svg';
import mountain from '../../assets/ecosystem-logos/mountain.svg';
import lombard from '../../assets/ecosystem-logos/lombard.svg';
import level from '../../assets/ecosystem-logos/level.svg';
import yieldNest from '../../assets/ecosystem-logos/yield-nest.svg';
import verisense from '../../assets/ecosystem-logos/verisense.svg';
import chainflow from '../../assets/ecosystem-logos/chainflow.svg';
import veil from '../../assets/ecosystem-logos/veil.svg';
import stakefish from '../../assets/ecosystem-logos/stakefish.svg';
import bwarelabs from '../../assets/ecosystem-logos/bware-labs.svg';
import imperator from '../../assets/ecosystem-logos/imperator.svg';
import kukis from '../../assets/ecosystem-logos/kukis.svg';
import nodemonster from '../../assets/ecosystem-logos/node-monster.svg';

export const ITEMS = [
  {
    src: kuda,
    alt: 'kuda',
    name: 'KUDA',
    desc: 'Karak Universal Data Availability, KUDA, is the first DA marketplace designed to seamlessly connect rollups with any DA layer.',
    link: 'https://subseaprotocol.com',
    type: 'Project',
  },
  {
    src: wormhole,
    alt: 'wormhole',
    name: 'Wormhole',
    desc: 'Wormhole is the leading interoperability platform that facilitates the secure transfer of arbitrary messages across chains.',
    link: 'https://wormhole.com',
    type: 'Project',
  },
  {
    src: frax,
    alt: 'frax',
    name: 'Frax',
    desc: 'Frax aims to create a scalable, algorithmic, and partially collateralized stablecoin called FRAX, which maintains its peg to the US dollar.',
    link: 'https://frax.finance',
    type: 'Project',
  },
  {
    src: spacetime,
    alt: 'space-and-time',
    name: 'Space and Time',
    desc: 'Space and Time is the first decentralized data warehouse that delivers sub-second ZK proofs against onchain and offchain data.',
    link: 'https://www.spaceandtime.io',
    type: 'Project',
  },
  {
    src: interstate,
    alt: 'interstate',
    name: 'Interstate',
    desc: 'Interstate network seeks to make Ethereum transactions near instant and allow apps to self sequence while maintaining full composability with the L1.',
    link: 'https://www.interstate.so',
    type: 'Project',
  },
  {
    src: hyperbolic,
    alt: 'hyperbolic',
    name: 'Hyperbolic',
    desc: 'Hyperbolic unites global compute to provide accessible, affordable, and scalable GPU resources and AI services.',
    link: 'https://hyperbolic.xyz',
    type: 'Project',
  },
  {
    src: hypernative,
    alt: 'hypernative',
    name: 'Hypernative',
    desc: 'Hypernative stops zero-day Web3 cyber attacks and protects digital assets, protocols, and Web3 applications from significant losses.',
    link: 'https://www.hypernative.io',
    type: 'Project',
  },
  {
    src: hyperlane,
    alt: 'hyperlane',
    name: 'Hyperlane',
    desc: 'Hyperlane is the first interoperability layer that enables you to permissionlessly connect any blockchain, out-of-the-box.',
    link: 'https://www.hyperlane.xyz',
    type: 'Project',
  },
  {
    src: subsea,
    alt: 'subsea',
    name: 'Subsea',
    desc: 'Subsea is a risk management marketplace with an automated invariant detection mechanism to secure users against risks, hacks, and attacks.',
    link: 'https://subseaprotocol.com',
    type: 'Project',
  },
  {
    src: slashproof,
    alt: 'slashproof',
    name: 'SlashProof',
    desc: 'SlashProof is the only place to protect restaked assets, secured by zero-knowledge proofs.',
    link: 'https://slashproof.org',
    type: 'Project',
  },
  {
    src: verisense,
    alt: 'verisense',
    name: 'Verisense',
    desc: `Verisense is the world’s first FHE-enabled VaaS network designed to plug and play with any restaking layers.`,
    link: 'https://verisense.network',
    type: 'Project',
  },
  {
    src: pendle,
    alt: 'pendle',
    name: 'Pendle',
    desc: 'Pendle Finance is a decentralized finance (DeFi) platform that allows users to trade tokenized future yield and manage yield-bearing assets.',
    link: 'https://www.pendle.finance',
    type: 'Project',
  },
  {
    src: coinbase,
    alt: 'coinbase',
    name: 'Coinbase Staking',
    desc: 'Coinbase cbETH is a Liquid Staking Token created and maintained by Coinbase, which represents Ethereum staked through Coinbase.',
    link: 'https://www.coinbase.com/earn',
    type: 'Project',
  },
  {
    src: binance,
    alt: 'binance',
    name: 'Binance Staking',
    desc: 'Binance wBETH is a Liquid Staking Token created and maintained by Binance, which represents Ethereum staked through Binance.',
    link: 'https://www.binance.com/en/earn',
    type: 'Project',
  },
  {
    src: ethereum,
    alt: 'ethereum',
    name: 'Ethereum',
    desc: 'Ethereum is a decentralized, open-source blockchain that enables the creation and execution of smart contracts and decentralized applications.',
    link: 'https://ethereum.org',
    type: 'Project',
  },
  {
    src: arbitrum,
    alt: 'arbitrum',
    name: 'Arbitrum',
    desc: 'Arbitrum is a Layer 2 scaling solution for the Ethereum blockchain that powers fast smart contract transactions while reducing transaction costs.',
    link: 'https://arbitrum.io',
    type: 'Project',
  },
  {
    src: circle,
    alt: 'circle',
    name: 'Circle',
    desc: 'USDC is a stablecoin that is pegged to the US dollar on a 1:1 basis. Every UNIT is backed by a dollar or equivalent asset held in reserve.',
    link: 'https://www.circle.com/en/usdc',
    type: 'Project',
  },
  {
    src: tether,
    alt: 'tether',
    name: 'Tether',
    desc: 'USDT is a cryptocurrency stablecoin pegged to the US dollar, designed to combine blockchain technology with the stable value of the dollar.',
    link: 'https://tether.to',
    type: 'Project',
  },
  {
    src: bnb,
    alt: 'bnb',
    name: 'BNB',
    desc: 'BNB is a blockchain developed by Binance that provides high performance and low cost transactions for various DeFi applications.',
    link: 'https://www.bnbchain.org',
    type: 'Project',
  },
  {
    src: mantle,
    alt: 'mantle',
    name: 'Mantle',
    desc: 'Mantle is an L2 that offers high performance and low fees, designed to enhance the UX and capabilities of decentralized applications.',
    link: 'https://www.mantle.xyz',
    type: 'Project',
  },
  {
    src: lido,
    alt: 'lido',
    name: 'Lido',
    desc: 'Lido stETH is a Liquid Staking Token that represents staked ether in Lido, combining the value of the initial deposit + staking rewards.',
    link: 'https://lido.fi',
    type: 'Project',
  },
  {
    src: k2,
    alt: 'K2',
    name: 'K2',
    desc: 'K2 is a modular, Layer 2 blockchain with native risk management, shared security, and AI infrastructure built for the next billion users.',
    link: '',
    type: 'Project',
  },
  {
    src: caldera,
    alt: 'caldera',
    name: 'Caldera',
    desc: 'Caldera is a rollup deployment platform to launch high-performance, customizable, application-specific rollups with Arbitrum and Optimism frameworks.',
    link: 'https://www.caldera.xyz',
    type: 'Project',
  },
  {
    src: etherfi,
    alt: 'etherfi',
    name: 'Etherfi',
    desc: 'EtherFi is a decentralized staking protocol that allows users to stake their ETH and earn rewards without needing to lock up their funds.',
    link: 'https://www.ether.fi',
    type: 'Project',
  },
  {
    src: spark,
    alt: 'spark',
    name: 'Spark',
    desc: 'Spark is a DeFi protocol within the MakerDAO ecosystem, allowing users to participate in various DeFi activities using the Dai Savings Rate.',
    link: 'https://spark.fi',
    type: 'Project',
  },
  {
    src: bitgo,
    alt: 'bitgo',
    name: 'BitGo Staking',
    desc: 'Wrapped Bitcoin is an ERC-20 cryptocurrency token that runs on the Ethereum blockchain and is intended to be backed 1:1 by Bitcoin.',
    link: 'https://www.bitgo.com',
    type: 'Project',
  },
  {
    src: ethena,
    alt: 'ethena',
    name: 'Ethena',
    desc: 'Ethena is a DeFi protocol with a synthetic dollar stablecoin called USDe, providing yield through a combination of staking Ether and shorting Ether futures.',
    link: 'https://ethena.fi',
    type: 'Project',
  },
  {
    src: rocketPool,
    alt: 'rocket-pool',
    name: 'Rocket Pool',
    desc: "Rocket Pool rETH is a Liquid Staking Token which represents a user's proportional claim to ETH in Rocket Pool.",
    link: 'https://rocketpool.net',
    type: 'Project',
  },
  {
    src: redacted,
    alt: 'redacted',
    name: 'Redacted Cartel',
    desc: 'DAO focused on building a decentralized liquidity layer for the DeFi ecosystem, using a combination of bonding, staking, and decentralized governance.',
    link: 'https://redacted.finance',
    type: 'Project',
  },
  {
    src: renzo,
    alt: 'renzo',
    name: 'Renzo',
    desc: 'Renzo allows users to pool their staked tokens to earn rewards and serves as an interface to restake their ETH or Liquid Staking Tokens.',
    link: 'https://www.renzoprotocol.com',
    type: 'Project',
  },
  {
    src: swell,
    alt: 'swell',
    name: 'Swell',
    desc: 'Swell is a non-custodial staking protocol aiming to deliver the world’s best liquid staking and restaking experience.',
    link: 'https://www.swellnetwork.io',
    type: 'Project',
  },
  {
    src: kelp,
    alt: 'kelp',
    name: 'Kelp',
    desc: 'Kelp DAO is a liquid restaking protocol designed to enhance liquidity for restaked assets by offering tokens that represent otherwise illiquid assets.',
    link: 'https://kelpdao.xyz',
    type: 'Project',
  },
  {
    src: puffer,
    alt: 'puffer',
    name: 'Puffer',
    desc: 'Puffer Finance is a native liquid restaking protocol that enhances the performance and diversity of Ethereum proof-of-stake validators.',
    link: 'https://www.puffer.fi',
    type: 'Project',
  },
  {
    src: magpie,
    alt: 'magpie',
    name: 'Magpie',
    desc: 'Magpie is a multichain DeFi protocol that facilitates cross-chain swaps and boosts yield for liquidity providers and governance token holders.',
    link: 'https://www.magpiexyz.io',
    type: 'Project',
  },
  {
    src: bedrock,
    alt: 'bedrock',
    name: 'Bedrock',
    desc: 'Bedrock is a multiasset liquid restaking protocol backed by non-custodial solution designed in partnership with RockX.',
    link: 'https://www.bedrock.technology',
    type: 'Project',
  },
  {
    src: stader,
    alt: 'stader',
    name: 'Stader',
    desc: 'Stader is a liquid staking platform that enables users to stake their assets to earn rewards while retaining the liquidity of their staked assets.',
    link: 'https://www.staderlabs.com',
    type: 'Project',
  },
  {
    src: lista,
    alt: 'lista',
    name: 'Lista',
    desc: 'Lista DAO functions as a open-source liquidity protocol for earning yields on collateralized crypto assets.',
    link: 'https://lista.org',
    type: 'Project',
  },
  {
    src: solv,
    alt: 'solv',
    name: 'Solv',
    desc: 'Solv is revolutionizing yield aggregation and liquidity management by tokenizing and consolidating high-quality yields from diverse sources.',
    link: 'https://solv.finance',
    type: 'Project',
  },
  {
    src: originEther,
    alt: 'origin-protocol',
    name: 'Origin',
    desc: 'Origin Ether is a Liquid Staking Token that earns yield from all forms of staking and offers a tighter peg to ETH.',
    link: 'https://www.originprotocol.com',
    type: 'Project',
  },
  {
    src: ignition,
    alt: 'ignition',
    name: 'Ignition',
    desc: 'Ignition FBTC is a decentralized protocol that enables the seamless transfer and management of Bitcoin across various blockchain networks.',
    link: 'https://fbtc.com',
    type: 'Project',
  },
  {
    src: elixir,
    alt: 'elixir',
    name: 'Elixir',
    desc: 'Elixir is modular DPoS network built to power liquidity across orderbook exchanges.',
    link: 'https://www.elixir.xyz',
    type: 'Project',
  },
  {
    src: usual,
    alt: 'usual',
    name: 'Usual',
    desc: 'Usual is a secure and decentralized fiat-backed stablecoin issuer that redistributes ownership and value through the $USUAL token.',
    link: 'https://usual.money',
    type: 'Project',
  },
  {
    src: derive,
    alt: 'derive',
    name: 'Derive',
    desc: 'Derive is a decentralized protocol that creates unique and programmable onchain options, perpetuals, and structured products.',
    link: 'https://www.derive.xyz',
    type: 'Project',
  },
  {
    src: mountain,
    alt: 'mountain-protocol',
    name: 'Mountain Protocol',
    desc: 'Mountain USDM is a yield-bearing institutional-grade stablecoin backed by T-Bills.',
    link: 'https://mountainprotocol.com',
    type: 'Project',
  },
  {
    src: lombard,
    alt: 'lombard',
    name: 'Lombard',
    desc: 'Lombard LBTC is a Bitcoin Liquid Staking Token that earns yield through Babylon Staking and can move seamlessly across chains as collateral without compromising security.',
    link: 'https://www.lombard.finance',
    type: 'Project',
  },
  {
    src: level,
    alt: 'level',
    name: 'Level',
    desc: 'Level is a stablecoin protocol powered by restaked dollar tokens like USDT and USDC.',
    link: 'https://www.level.money',
    type: 'Project',
  },
  {
    src: yieldNest,
    alt: 'yield-nest',
    name: 'YieldNest',
    desc: 'YieldNest is a next-generation liquid restaking protocol that provides simple-to-understand and risk-adjusted restaking strategies.',
    link: 'https://www.yieldnest.finance',
    type: 'Project',
  },
  {
    src: nethermind,
    alt: 'nethermind',
    name: 'Nethermind',
    desc: 'Engineering and research solutions, empowering developers & enterprises to build upon the decentralized web.',
    link: 'https://www.nethermind.io',
    type: 'Operator',
  },
  {
    src: animoca,
    alt: 'animoca-brands',
    name: 'Animoca Brands',
    desc: "We aim to deliver Digital Property Rights to the world's gamers & internet users, and build the open #met.",
    link: 'https://www.animocabrands.com',
    type: 'Operator',
  },
  {
    src: allnodes,
    alt: 'allnodes',
    name: 'Allnodes',
    desc: 'Allnodes is a non-custodial staking and node hosting platform and advanced infrastructure provider, making it easy and safe for all.',
    link: 'https://www.allnodes.com',
    type: 'Operator',
  },
  {
    src: everstake,
    alt: 'everstake',
    name: 'Everstake',
    desc: 'Everstake is a major blockchain company focused on providing essential services to blockchains, investment, staking, and guardianship.',
    link: 'https://everstake.one',
    type: 'Operator',
  },
  {
    src: hashkey,
    alt: 'hashkey-cloud',
    name: 'Hashkey Cloud',
    desc: 'A world-leading blockchain staking service provider by Haskey Group.',
    link: 'https://www.hashkey.cloud',
    type: 'Operator',
  },
  {
    src: okx,
    alt: 'okx',
    name: 'OKX',
    desc: "The only app you’ll need for 'all things crypto'.",
    link: 'https://www.okx.com',
    type: 'Operator',
  },
  {
    src: ankr,
    alt: 'ankr',
    name: 'Ankr',
    desc: 'Build web3 apps with a full suite of developer tools and power them with fast, global, decentralized connections to dozens of chains.',
    link: 'https://www.ankr.com/',
    type: 'Operator',
  },
  {
    src: dsrv,
    alt: 'dsrv',
    name: 'DSRV',
    desc: 'Everything distributed, served complete.',
    link: 'https://www.dsrvlabs.com',
    type: 'Operator',
  },
  {
    src: proofgroup,
    alt: 'proof-group',
    name: 'Proof Group',
    desc: 'We are a team of former crypto founders and venture investors, backing the next generation of founders building disruptive financial technology.',
    link: 'https://www.proofgroup.xyz',
    type: 'Operator',
  },
  {
    src: infstones,
    alt: 'infstones',
    name: 'InfStones',
    desc: 'The Ultimate Web3 Infrastructure Platform supporting over 80+ blockchain protocols.',
    link: 'https://infstones.com',
    type: 'Operator',
  },
  {
    src: validationcloud,
    alt: 'validation-cloud',
    name: 'Validation Cloud',
    desc: 'Connecting organizations into Web3 through a fast, scalable, and intelligent platform.',
    link: 'https://www.validationcloud.io',
    type: 'Operator',
  },
  {
    src: infrasingularity,
    alt: 'infrasingularity',
    name: 'InfraSingularity',
    desc: 'InfraSingularity aims to radically transform the Web3 ecosystem as a pioneering Web3 Investor, and builder.',
    link: 'https://infrasingularity.com',
    type: 'Operator',
  },
  {
    src: rockx,
    alt: 'rockx',
    name: 'RockX',
    desc: 'Stake and build across multiple chains seamlessly with enterprise-grade blockchain infrastructure & staking solutions.',
    link: 'https://www.rockx.com',
    type: 'Operator',
  },
  {
    src: luganodes,
    alt: 'luganodes',
    name: 'Luganodes',
    desc: "World's leading blockchain infrastructure provider from Lugano Plan B, an initiative by Lugano & Tether.",
    link: 'https://www.luganodes.com',
    type: 'Operator',
  },
  {
    src: piertwo,
    alt: 'pier-two',
    name: 'Pier Two',
    desc: 'Enterprise-grade infrastructure for institutional clients, Non-custodial ETH Staking, and Secure Node & Validation Services.',
    link: 'https://piertwo.com',
    type: 'Operator',
  },
  {
    src: finoa,
    alt: 'finoa',
    name: 'Finoa',
    desc: 'Empowering institutions to safely engage with crypto.',
    link: 'https://www.finoa.io',
    type: 'Operator',
  },
  {
    src: validao,
    alt: 'validao',
    name: 'ValiDAO',
    desc: 'Free-range, locally sourced, organic, seed-oil-free consensus services for an onchain world.',
    link: 'https://validao.xyz',
    type: 'Operator',
  },
  {
    src: kiln,
    alt: 'kiln',
    name: 'Kiln',
    desc: 'Kiln makes enterprise-grade staking easy.',
    link: 'https://www.kiln.fi',
    type: 'Operator',
  },
  {
    src: a41,
    alt: 'a41',
    name: 'A41',
    desc: 'Bring cryptoeconomy to real life.',
    link: 'https://www.a41.io/',
    type: 'Operator',
  },
  {
    src: meria,
    alt: 'meria',
    name: 'Meria',
    desc: 'Build your crypto wealth.',
    link: 'https://www.meria.com',
    type: 'Operator',
  },
  {
    src: chainflow,
    alt: 'chainflow',
    name: 'Chainflow',
    desc: 'Building crypto infrastructure for a better world.',
    link: 'https://chainflow.io',
    type: 'Operator',
  },
  {
    src: veil,
    alt: 'veil',
    name: 'Veil',
    desc: 'Improving the state of privacy-focused cryptocurrencies by creating a secure, highly anonymous coin that provides full-time privacy.',
    link: 'https://veil-project.com',
    type: 'Operator',
  },
  {
    src: stakefish,
    alt: 'stakefish',
    name: 'Stakefish',
    desc: 'Classic staking for native ETH staking with automated consensus rewards, and EigenLayer staking for additional rewards.',
    link: 'https://stake.fish',
    type: 'Operator',
  },
  {
    src: bwarelabs,
    alt: 'bwarelabs',
    name: 'BwareLabs',
    desc: "Bware Labs offers the industry's highest-performance and most reliable infrastructure services and development tools.",
    link: 'https://bwarelabs.com',
    type: 'Operator',
  },
  {
    src: imperator,
    alt: 'imperator',
    name: 'Imperator',
    desc: 'Imperator empowers investors and token holders to effortlessly secure blockchain networks through non-custodial staking.',
    link: 'https://www.imperator.co',
    type: 'Operator',
  },
  {
    src: kukis,
    alt: 'kukis-global',
    name: 'Kukis Global',
    desc: 'Kukis Global specializes in building and maintaining highly available infrastructure, from design and architecture to 24/7 support for distributed systems.',
    link: 'https://www.kukis-global.com',
    type: 'Operator',
  },
  {
    src: nodemonster,
    alt: 'node-monster',
    name: 'Node.Monster',
    desc: 'Powering networks with trusted nodes.',
    link: 'https://node.monster',
    type: 'Operator',
  },
];
